import React from 'react'

const Iframe: React.FC = () => {
  return <div>hi!</div>
}

export default Iframe

const domains = ['https://doseapp.io', 'https://dosejournal.com']

const messageHandler = (event: MessageEvent) => {
  /* if (!domains.includes(event.origin)) return; */
  const { action, key, value } = event.data
  console.log('[if] received event', action, key)

  if (action == 'save') {
    window.localStorage.setItem(key, JSON.stringify(value))
  } else if (action == 'get') {
    ;(event?.source as Window).postMessage(
      {
        action: 'returnData',
        key,
        value: JSON.parse(window.localStorage.getItem(key) || '{}')
      },
      '*'
    )
  }
}

if (typeof window !== 'undefined')
  window.addEventListener('message', messageHandler, false)
